const storage = {
  data: {
    ITEMS_EXCLUDED_BY_PREFILTER: {},

    ITEMS: {}, // PREFILTERED
    ITEMS_MERGED: {},
    GROUPS_MERGED: {},
    GROUPS_MERGED_FILTERED: {},
    GROUPS_MERGED_FILTERED_SORTED: {},

    LINEITEMS: [],
    ORDERS: [],
  },

  get(dataType) {
    return this.data[dataType]
  },
  set(dataType, { endpointKey, items, moduleName, reportId = '-1', subId = '-1' }) {
    const setAll = () => {
      if (!this.data[dataType][endpointKey]) {
        this.data[dataType][endpointKey] = { all: Object.freeze(items) }
      } else {
        this.data[dataType][endpointKey].all = Object.freeze(items)
      }
    }
    const setId = () => {
      if (!this.data[dataType][endpointKey]) {
        this.data[dataType][endpointKey] = { [reportId]: { [subId]: Object.freeze(items) } }
      } else if (!this.data[dataType][endpointKey][reportId]) {
        this.data[dataType][endpointKey][reportId] = { [subId]: Object.freeze(items) }
      } else {
        this.data[dataType][endpointKey][reportId][subId] = Object.freeze(items)
      }
    }
    const setSupportingCollection = () => {
      setAll()
    }
    const setReportCollection = () => {
      if (reportId) {
        setId()
      } else {
        setAll()
      }
    }

    const isSupportingCollection = endpointKey !== moduleName

    return isSupportingCollection ? setSupportingCollection() : setReportCollection()
  },

  getStoredState(storageKey) {
    return window.localStorage.getItem(storageKey)
  },
  getStoredJson(storageKey) {
    const storedState = this.getStoredState(storageKey)

    if (!storedState) return null

    try {
      return JSON.parse(storedState)
    } catch {
      return storedState
    }
  },
  validateStoredDrawerState(storedState) {
    return ['false', 'true'].indexOf(storedState) !== -1
  },
  getDrawerState(storageKey) {
    const storedState = this.getStoredState(storageKey)
    const validStoredState = this.validateStoredDrawerState(storedState)

    return validStoredState ? storedState : 'true'
  },
  getNavCollapsedState(drawerState) {
    return drawerState === 'true'
  },
  getTheme(storageKey) {
    return this.getStoredState(storageKey)
  },
}

export default storage
