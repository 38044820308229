import axios from 'axios'

import logger from 'src/plugins/logger'

const { NODE_ENV, VUE_APP_API_HOST, VUE_APP_API_MOCK } = process.env

const ERROR = {
  API_RESPONSE: () => ({
    details: 'The API responded with an error.',
    error: {
      code: 500,
      key: 'API_RESPONSE',
      layer: 'api/data',
      message: 'API Request Failed',
    },
    hint: 'This is likely due to a malformed request or misconfigured endpoint.',
  }),
  API_UNAVAILABLE: () => ({
    details: 'The API is unavailable or unable to process your request.',
    error: {
      code: 503,
      key: 'API_UNAVAILABLE',
      layer: 'api',
      message: 'API Connection Error',
    },
    hint: 'Possible reasons include a malformed request, a network interruption, scheduled downtime, or an unexpected disruption from Google.',
  }),
  AUTH_UNAVAILABLE: () => ({
    details: 'The auth service is unavailable.',
    error: {
      code: 503,
      key: 'AUTH_UNAVAILABLE',
      layer: 'app/data',
      message: 'Auth Service Unavailable',
    },
    hint: 'Possible reasons include a network interruption, scheduled downtime, or an unexpected disruption from Google.',
  }),
  ERROR_CAUGHT: (caughtError) => ({
    caughtError,
    details: `The application encountered the following message: ${caughtError.message}`,
    error: {
      code: 500,
      key: 'ERROR_CAUGHT',
      layer: 'app',
      message: 'Error Caught',
    },
    hint: 'This may be due to a script error, typically seen when the script encounters unexpected conditions.',
  }),
  ERROR_UNKNOWN: () => ({
    details: 'A traceless error has occurred. Our developers have been informed.',
    error: {
      code: 500,
      key: 'ERROR_UNKNOWN',
      layer: 'app',
      message: 'Error Unknown',
    },
    hint: 'This error lacks a stack trace. Developers will contact affected users if further information is required to reproduce this issue. If no session is associated with this event, please reach out to the developers with any relevant details around your experience. Screenshots are also welcome.',
  }),
  NETWORK_UNAVAILABLE: () => ({
    details: 'A network connection error has occurred.',
    error: {
      code: 503,
      key: 'NETWORK_UNAVAILABLE',
      layer: 'network',
      message: 'Network Connection Failed',
    },
    hint: 'This may be due to an interrupted connection attempt, typically seen when the application encounters a slow or offline network.',
  }),
  SERVICE_UNAVAILABLE: () => ({
    details: 'A service error has occurred.',
    error: {
      code: 503,
      key: 'SERVICE_UNAVAILABLE',
      layer: 'app',
      message: 'Service Unavailable',
    },
    hint: 'Possible reasons include a network interruption or scheduled downtime.',
  }),
}

const http = {
  ERROR,
  setup(allow) {
    const allowed = this.allow.set(allow)
    const config = this.config.set(allowed)

    return this.client.set(allowed, config)
  },

  requester: null,
  setRequester(email) {
    // @TODO Add email validation to `http.requester`.
    this.requester = email
  },

  allow: {
    instance: null,
    get(allowed) {
      const validEnv = !['test'].includes(NODE_ENV)

      return allowed && validEnv
    },
    set(allowed) {
      this.instance = this.get(allowed)
      return this.instance
    },
  },
  client: {
    instance: null,
    get(allowed, config) {
      if (allowed) return axios.create(config)
      return {}
    },
    set(allowed, config) {
      this.instance = this.get(allowed, config)
      return this.instance
    },
  },
  config: {
    data: {},
    get(allowed, additionalHeaders = {}) {
      const baseURL = (allowed && VUE_APP_API_HOST) || VUE_APP_API_MOCK
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
      const additionalHeaderKeys = Object.keys(additionalHeaders)

      if (additionalHeaderKeys.length) {
        additionalHeaderKeys.forEach((headerKey) => {
          headers[headerKey] = additionalHeaders[headerKey]
        })
      }

      return {
        baseURL,
        headers,
        credentials: 'same-origin',
      }
    },
    set(allowed) {
      this.data = this.get(allowed)
      return this.data
    },
  },

  setRangeHeader(range) {
    this.client.instance.defaults.headers.common['Range-Unit'] = range
  },
  clearRangeHeader() {
    delete this.client.instance.defaults.headers.common['Range-Unit']
  },

  setAuthorizationHeader(token) {
    this.client.instance.defaults.headers.common.Authorization = `Bearer ${token}`
  },
  clearAuthToken() {
    delete this.client.instance.defaults.headers.common.Authorization
  },

  isStatusSuccess(status) {
    return status >= 200 && status < 400
  },
  getErrorType(response) {
    const { content = {}, details } = response || {}

    if (content.Code || content.Message) return 'database'
    if (details || content.details) return 'api'

    return false
  },
  setErrorMetadata(caughtError, method, uri) {
    const [apiPath, apiQuery = ''] = uri.split('?') // ['timesheet/person', 'personEmail=example@adops.com']
    const { hostname, pathname, protocol, query = '' } = window.document.location
    const makeQuery = (str) => (str.length && str.split('&').map((queryString) => {
      const [key, value] = queryString.split('=')
      return { [key]: (key === 'personEmail' && 'REDACTED_VALUE') || value }
    })) || []
    const [apiProtocol, apiHost] = VUE_APP_API_HOST.split('://')

    return {
      from: {
        env: hostname.replace(/\.?iadops\.com/, '') || 'production',
        path: pathname,
        query: makeQuery(query),
        secure: protocol === 'https',
      },
      method,
      to: {
        env: apiHost.replace(/\.?api\.iadops\.com/, '') || 'production',
        path: apiPath,
        query: makeQuery(apiQuery),
        secure: apiProtocol === 'https',
      },
    }
  },
  catchRequest(caughtError, method, uri) {
    const apiError = () => (caughtError.status >= 200 && caughtError.status < 400 && ERROR.API_RESPONSE()) || ERROR.API_UNAVAILABLE()
    const normalizedError = (navigator.onLine && apiError()) || ERROR.NETWORK_UNAVAILABLE()

    normalizedError.caughtError = caughtError
    normalizedError.error.metadata = this.setErrorMetadata(caughtError, method, uri)

    return normalizedError
  },
  /**
   * Catches request and response errors
   * @param {object} caughtError Caught error or normalized error
   * @returns {object} A normalized error the views can handle
   */
  catch(caughtError = {}) {
    const getNormalizedError = () => caughtError.error && caughtError
    const normalizeError = () => {
      // Defaults response to caughtError in case of sudden unhandled error.
      const { isAxiosError, response = caughtError } = caughtError
      const data = (isAxiosError && response) || caughtError

      const errorFirebaseUnavailable = () => data.code === 'auth/network-request-failed' && ERROR.AUTH_UNAVAILABLE()
      const errorRequestFailed = () => data.message === 'Network Error' && this.catchRequest(caughtError)
      const errorCaught = () => caughtError.stack && ERROR.ERROR_CAUGHT(caughtError)
      const errorUnknown = () => ERROR.ERROR_UNKNOWN(caughtError)

      const normalizedError = errorFirebaseUnavailable() || errorRequestFailed() || errorCaught() || errorUnknown()

      if (!normalizedError.caughtError) normalizedError.caughtError = new Error(normalizedError.message)
      if (data.metadata) normalizedError.error.metadata = data.metadata

      return normalizedError
    }

    const normalizedError = getNormalizedError() || normalizeError()
    const { code, key, layer } = normalizedError.error

    normalizedError.errorId = logger.sentry.captureException(normalizedError.caughtError, {
      tags: { code, key, layer },
    })

    return normalizedError
  },
  error(data = {}) {
    return (data.error && data) || this.catch(data)
  },
  success(data) {
    data.error = false

    return data
  },
  response(response) {
    const { data = response, status } = response

    if (status) data.status = status

    const isStatusSuccess = this.isStatusSuccess(status)
    const isError = data.error || data.layer || data.stack
    const isSuccessResponse = isStatusSuccess && !isError

    return (isSuccessResponse && this.success(data)) || this.error(data)
  },

  async get(uri, config = http.config.data) {
    if (!this.client?.instance?.get) {
      return Promise.reject(ERROR.SERVICE_UNAVAILABLE())
    }

    const response = await this.client.instance.get(uri, config).catch((error) => {
      const method = 'get'
      return this.catchRequest(error, method, uri)
    })

    return this.response(response)
  },
  async put(uri, data, config = http.config.data) {
    if (!this.client?.instance?.put) {
      return Promise.reject(ERROR.SERVICE_UNAVAILABLE())
    }

    const response = await this.client.instance.put(uri, data, config).catch((error) => {
      const method = 'put'
      return this.catchRequest(error, method, uri)
    })
    return this.response(response)
  },
  async post(uri, data, config = http.config.data) {
    if (!this.client?.instance?.post) {
      return Promise.reject(ERROR.SERVICE_UNAVAILABLE())
    }

    const response = await this.client.instance.post(uri, data, config).catch((error) => {
      const method = 'post'
      return this.catchRequest(error, method, uri)
    })
    return this.response(response)
  },
  async delete(uri, config = http.config.data) {
    if (!this.client?.instance?.delete) {
      return Promise.reject(ERROR.SERVICE_UNAVAILABLE())
    }

    const response = await this.client.instance.delete(uri, config).catch((error) => {
      const method = 'delete'
      return this.catchRequest(error, method, uri)
    })
    return this.response(response)
  },
  async head(uri, config = http.config.data) {
    if (!this.client?.instance?.head) {
      return Promise.reject(ERROR.SERVICE_UNAVAILABLE())
    }

    const response = await this.client.instance.head(uri, config).catch((error) => {
      const method = 'head'
      return this.catchRequest(error, method, uri)
    })
    return this.response(response)
  },
}

const allowRequests = true

http.setup(allowRequests)

export default http
