import Vue from 'vue'
import Vuex from 'vuex'

import auth from '../auth/store/store'
import layout from '../layout/store/store'
import messages from '../messages/store/store'
import tools from '../tools/store/store'

Vue.use(Vuex)

const AppStore = new Vuex.Store({
  state: {
    homeRoute: 'dashboard',
    route: { name: '', params: {}, query: {} },
    storeNameMap: {
      // Landing
      '': 'dashboard',
      dashboard: 'dashboard',
      docs: 'docs',

      // Internal
      person: 'internal',
      organization: 'internal',
      project: 'internal',
      time: 'internal',

      // Reports
      admanager: 'admanager',
      'adx-health': 'delivery',
      'adx-and-ob': 'delivery',
      'custom-reports': 'customReports',
      explore: 'internal',
      'yield-partner': 'delivery', // @deprecated
      discrepancy: 'delivery',
      pacing: 'delivery',
      'network-health': 'delivery',
    },
  },
  mutations: {
    route(state, route) {
      state.route = route
    },
  },
  getters: {
    routeName(state) {
      return state.route.name
    },
    reportName(state) {
      return state.route.params?.reportName || state.homeRoute
    },
    reportPath(state) {
      return state.route.params?.reportPath
    },
    storeName(state, getters) {
      // Landing Pages: ['custom-reports', 'docs']
      const landingPageMap = {
        'custom-reports': 'customReports',
        docs: 'docs',
      }

      const storeKey = landingPageMap[getters.routeName] || getters.reportName

      return state.storeNameMap[storeKey]
    },
  },
  actions: {
    getStoreName({ state }, reportName) {
      return state.storeNameMap[reportName]
    },

    isStoreRegistered(context, moduleName) {
      return AppStore.hasModule(moduleName)
    },

    async getStore(context, storeName) {
      const storeMap = {
        admanager: () => import('@/admanager/store/store'),
        customReports: () => import('@/custom-reports/store/store'),
        dashboard: () => import('@/dashboard/store/store'),
        delivery: () => import('@/delivery/store/store'),
        docs: () => import('@/docs/store/store'),
        internal: () => import('@/internal/store/store'),
        programmatic: () => import('@/programmatic/store/store'),

        explore: () => import('@/internal/store/store'),

        discrepancy: () => import('@/delivery/store/store'),
        pacing: () => import('@/delivery/store/store'),

        'network-health': () => import('@/programmatic/store/store'),
        'adx-health': () => import('@/programmatic/store/store'),
        'yield-partner': () => import('@/programmatic/store/store'), // @deprecated
        'adx-and-ob': () => import('@/programmatic/store/store'),

        'custom-reports': () => import('@/custom-reports/store/store'),

        organization: () => import('@/internal/store/store'),
        person: () => import('@/internal/store/store'),
        project: () => import('@/internal/store/store'),
        time: () => import('@/internal/store/store'),
        'time-page': () => import('@/internal/store/store'),
      }
      const { default: newStore } = await storeMap[storeName]()

      return newStore
    },

    async registerModule({ dispatch }, storeName) {
      const newStore = await dispatch('getStore', storeName)

      AppStore.registerModule(storeName, newStore)

      return dispatch('isStoreRegistered', storeName)
    },
  },
  modules: {
    auth,
    layout,
    messages,
    tools,
  },
})

export default AppStore
