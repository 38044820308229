/**
 * @file Firebase
 * @module store/firebase
 * @desc Configures Firebase and oversees its usage
 * @since v4.12.0-prod...
 * @backport v5.9.3-beta.20240827.e9e4b63
 */

/**
 * @typedef {import('firebase/app-check').AppCheck} AppCheck
 */

import { initializeApp } from 'firebase/app'
import { getAnalytics, isSupported, logEvent, setUserId, setUserProperties } from 'firebase/analytics'
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check'
import {
  getAuth,
  GoogleAuthProvider as AuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signInWithRedirect,
  signOut,
} from 'firebase/auth'
import {
  collection,
  doc,
  enableIndexedDbPersistence,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  setDoc,
  where,
} from 'firebase/firestore'

const {
  NODE_ENV,
  VUE_APP_FIREBASE_STORAGE: FIREBASE_STORAGE,
  VUE_APP_FIREBASE_API_KEY: FIREBASE_API_KEY,
  VUE_APP_FIREBASE_APP_ID: FIREBASE_APP_ID,
  VUE_APP_FIREBASE_MEASUREMENT_ID: FIREBASE_MEASUREMENT_ID,
  VUE_APP_FIREBASE_MESSAGING_SENDER_ID: FIREBASE_MESSAGING_SENDER_ID,
  VUE_APP_FIREBASE_SCOPE: FIREBASE_SCOPE,
  VUE_APP_GOOGLE_CLIENT_ID: GOOGLE_CLIENT_ID,
  VUE_APP_GOOGLE_PROJECT: GOOGLE_PROJECT,

  // Firebase App Check
  VUE_APP_FIREBASE_APP_CHECK_DEBUG_TOKEN: FIREBASE_APP_CHECK,
  VUE_APP_RECAPTCHA_ENTERPRISE_SITE_KEY: RECAPTCHA_ENTERPRISE_SITE_KEY,
} = process.env

const IS_DEV_ENV = /dev/.test(NODE_ENV)

const FIREBASE_CONFIG = {
  apiKey: FIREBASE_API_KEY,
  appId: FIREBASE_APP_ID,
  authDomain: `${GOOGLE_PROJECT}.firebaseapp.com`,
  clientId: GOOGLE_CLIENT_ID,
  databaseURL: `https://${GOOGLE_PROJECT}.firebaseio.com`,
  projectId: GOOGLE_PROJECT,
  storageBucket: FIREBASE_STORAGE,
  measurementId: FIREBASE_MEASUREMENT_ID,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  scopes: FIREBASE_SCOPE,
}

// Initialize Firebase & export it.
const firebaseApp = initializeApp(FIREBASE_CONFIG)

// Save configured Store references.
let analytics = null

isSupported().then((isSupportedApp) => {
  if (isSupportedApp) analytics = getAnalytics(firebaseApp)
})

const Firestore = getFirestore(firebaseApp)
const Fireauth = getAuth(firebaseApp)

/**
 * @function getFireAppCheck
 * @desc Create a Firebase App Check instance with the given Firebase
 * app instance.
 * @param {FirebaseApp} app The Firebase app instance.
 * @return {AppCheck} The Firebase App Check instance.
 * @see https://firebase.google.com/docs/app-check/web/recaptcha-provider
 */
export const getFireAppCheck = (app) => {
  if (IS_DEV_ENV) {
    // Configure Firebase App Check with a debug token for local development.
    // Set to string to support multi-browser and multi-device dev sessions.
    // eslint-disable-next-line no-restricted-globals
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = FIREBASE_APP_CHECK
  }

  return initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(RECAPTCHA_ENTERPRISE_SITE_KEY),
    isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
  })
}

/**
 * @const {AppCheck} FireAppCheck
 * @desc This is the Firebase App Check instance initialized
 * with our Firebase app instance.
 */
export const FireAppCheck = getFireAppCheck(firebaseApp)

if (!IS_DEV_ENV) {
  enableIndexedDbPersistence(Firestore, {
    synchronizeTabs: true,
  }).catch((err) => {
    const logger = console

    if (err.code === 'failed-precondition') {
      logger.warn(`Firebase Persistence Failed Precondition\n
        Multiple tabs are open yet persistence can be enabled
        in only one tab at a time.`)
    } else if (err.code === 'unimplemented') {
      logger.warn('The current browser does not support Firebase persistence.')
    } else {
      logger.info('Firebase Persistence', err)
    }
  })
}

const firecollection = {
  docs: () => collection(Firestore, 'app/docs/links'),
  message: () => collection(Firestore, 'message'),
  organization: () => collection(Firestore, 'organization'),
  person: () => collection(Firestore, 'person'),
  report: () => collection(Firestore, 'report'),
  error(catcher) {
    return (FirebaseError) => {
      const error = {
        code: FirebaseError.code || 404,
        layer: 'app/data',
        message: FirebaseError.message || 'Record Not Found',
      }
      return catcher({ error })
    }
  },
  /**
   * Watches documents, running observers in response to document changes.
   * @param {string} collectionName The name of the document's collection.
   * @param {string} docId The id of the document to watch, e.g. the email for a person record.
   * @param {Object<{ error, next }>} observer The functions to call when the document changes.
   * @returns {Promise<() => void>} Resolves to a function used to stop watching the document.
   */
  async watch(collectionName, docId, observer) {
    const currentDoc = doc(Firestore, collectionName, docId)
    return onSnapshot(currentDoc, observer)
  },
  async collection(collectionName, catcher) {
    const currentCollection = this[collectionName]()
    const docs = await getDocs(currentCollection).catch(this.error(catcher))
    const results = []

    // Store responses are not real arrays; Instead, they offer a custom forEach method.
    if (docs && docs.forEach) {
      docs.forEach((document) => {
        const result = document.data()

        if (result) result.docId = document.id

        results.push(result)
      })
    }

    return results
  },
  async get(collectionName, docId, catcher) {
    const currentDoc = doc(Firestore, collectionName, docId)
    const document = await getDoc(currentDoc).catch(this.error(catcher))
    const response = (document && document.exists() && document.data()) || null

    if (response) response.docId = document.id

    return response
  },
  async set(collectionName, docId, newData, options) {
    const currentCollection = this[collectionName]()
    return setDoc(doc(currentCollection, docId), newData, options)
  },

  where(whereClauses) {
    return whereClauses.map((clause) => {
      const [field, operator, value] = clause
      return where(field, operator, value)
    })
  },
  async findAll(collectionName, whereClauses, catcher) {
    const currentCollection = this[collectionName]()
    const querySnapshot = query(currentCollection, ...whereClauses)
    const docs = await getDocs(querySnapshot).catch(this.error(catcher))
    const results = []

    // Store responses are not real arrays; Instead, they offer a custom forEach method.
    if (docs && docs.forEach) {
      docs.forEach((document) => {
        const result = document.data()

        if (result) result.docId = document.id

        results.push(result)
      })
    }

    return results
  },
}

const fireanalytics = {
  event(eventName, eventData) {
    if (analytics) {
      logEvent(analytics, eventName, eventData)
    }
  },
  async setUser(analyticsData) {
    const { analyticsUser, personId } = analyticsData

    setUserId(analytics, personId)

    return setUserProperties(analytics, analyticsUser)
  },
}

const fireauth = {
  onAuthStateChanged(fnSuccess, fnError) {
    return onAuthStateChanged(Fireauth, fnSuccess, fnError)
  },
  /**
   * @function signInWithPopup
   * @desc Signs a user in with a popup window.
   * @return {Promise<UserCredential>}
   */
  signInWithPopup() {
    const provider = new AuthProvider()
    return signInWithPopup(Fireauth, provider)
  },
  /**
   * @deprecated signInWithRedirect
   * @desc After v4.12 and v5.8, use Firebase's `signInWithPopup`
   * method instead. The redirect method is no longer recommended
   * due to security errors browsers raise. Users experience these
   * errors as a never-ending sign-in loop. The `signInWithPopup`
   * method is the recommended alternative. This method will be
   * removed in a future release.
   * @return {Promise<never>}
   */
  signInWithRedirect() {
    const provider = new AuthProvider()
    return signInWithRedirect(Fireauth, provider)
  },
  signOut() {
    return signOut(Fireauth)
  },

  getIdToken() {
    return Fireauth.currentUser.getIdToken()
  },
}

export {
  fireauth,
  fireanalytics,
  AuthProvider,
  firecollection,
}
